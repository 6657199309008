import { useAtom } from 'jotai';
import { userAtom } from '../atoms/user.js';
import { Button } from "@/ui/components/shadcn/button.js"
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
  } from "@/ui/components/shadcn/tooltip.js"
import { LogInIcon, LogOutIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

export const LogInOut = () => {
    const [user, setUser] = useAtom(userAtom);
    const navigate = useNavigate()

    return <TooltipProvider delayDuration={10}>
        <Tooltip>
        <TooltipTrigger asChild>
            <Button variant="outline" size="icon" onClick={() => user ? setUser(undefined) : navigate('/login')} >
                { user ? <LogOutIcon/> : <LogInIcon/>}
            </Button>
        </TooltipTrigger>
            <TooltipContent>
                { user ? 'Log Out' : 'Log In'}
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
}
