import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { Link, Route, Routes } from "react-router-dom";
import logo from "../assets/logo.png";
import { ModeToggle } from "@/ui/components/theme-toggle.js";
import { HealthStatus } from "@/ui/components/health.js"
import { LogInOut } from "@/ui/components/logInOut.js"
import { MailButton } from "@/ui/components/mailButton.js"
import { PrivacyPolicy, TermsOfService } from "@/ui/components/legal.js"
import { DonateButton } from "@/ui/components/donate.js"
import '../assets/home.css';

function Home() {

  return (
    <div className="Home">
      <div className="text-center">
        <img
          src={logo}
          className="logo"
          alt="logo"
        />
      </div>
      <h1>Diplomabot</h1>
      <br/>
      <HealthStatus />
      <br/>
      <div className="card">
        <LogInOut/>
        <MailButton/>
        <ModeToggle/>
        <DonateButton/>
      </div>
      <br/>
      <div className="flex flex-row gap-2">
        <TermsOfService/>
        <PrivacyPolicy/>
        <a target="_blank" href='https://donate.stripe.com/7sIg244dr4Ar0HS9AA'>Donate</a>
      </div>
    </div>
  );
}

export default Home;
