import { Button } from "@/ui/components/shadcn/button.js"
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
  } from "@/ui/components/shadcn/tooltip.js"
import { PiggyBankIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

export function DonateButton2(){
    return (
        <Button variant="outline" size="icon">
            <PiggyBankIcon/>
        </Button>
    )
}

export const DonateButton = () => {

    const navigate = useNavigate()

    return <TooltipProvider delayDuration={10}>
        <Tooltip>
        <TooltipTrigger asChild>
            <Button variant="outline" size="icon" onClick={()=>{}} >
                <PiggyBankIcon/>
            </Button>
        </TooltipTrigger>
            <TooltipContent>
                Donate
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
}
