import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { SyncStorage } from 'jotai/vanilla/utils/atomWithStorage';

declare global {
    interface SymbolConstructor {
        readonly observable: symbol;
    }
}

type User = {
    //id:number,
    email:string,
    token: string,
} | undefined
;

// const _userAtom = atom<User>(undefined);

// export const userAtom = atom<User,[User],void>(
//     (get)=> {
//         const userAtom = get(_userAtom);
//         if (userAtom) return userAtom
//         const userLocalStorage = window.localStorage.getItem('user');
//         return userLocalStorage ? JSON.parse(userLocalStorage) : undefined 
//     },
//     (_get,set,value)=>{
//         value === undefined ? window.localStorage.removeItem('user') :
//         window.localStorage.setItem('user',JSON.stringify(value));
//         set(_userAtom,value);
//     }
// )

const storage : SyncStorage<User> = {
    getItem(key, initialValue) {
        const storedValue = localStorage.getItem(key)
        return storedValue ? JSON.parse(storedValue) : initialValue
      },
      setItem(key, value) {
        return value === undefined ? 
        localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(value))
      },
      removeItem(key) {
        localStorage.removeItem(key)
      },
}

export const userAtom = atomWithStorage<User>('user',undefined,storage,{getOnInit:true});