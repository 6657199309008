import { atom, useAtom } from "jotai"

// todo; auth fetch from s3
import { Mail, mails } from "@/ui/components/test-emails.js"

type Config = {
  selected: Mail["id"] | null
}

const configAtom = atom<Config>({
  selected: mails[0].id,
})

export function useMail() {
  return useAtom(configAtom)
}
