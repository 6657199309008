

import { Mail } from "@/ui/components/mail.js"
import { accounts, mails } from "@/ui/components/test-emails.js"

export default function MailPage() {
  const defaultLayout = undefined
  const defaultCollapsed = undefined

  return (
    <>
      <div className="flex flex-col">
        <Mail
          accounts={accounts}
          mails={mails}
          defaultLayout={defaultLayout}
          defaultCollapsed={defaultCollapsed}
          navCollapsedSize={4}
        />
      </div>
    </>
  )
}
